/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from "react";

import "./imageComparisonSlider.css";
let animationLoop;

const SliderHandle = () => (
  <svg width="32" height="32" fill="none" viewBox="0 0 32 32">
    <circle
      cx="16"
      cy="16"
      r="16"
      fill="silver"
      id="circle2"
      style={{ fill: "#18d1a7", fillOpacity: 1 }}
    />
    <g
      id="layer1"
      transform="matrix(-0.03665034,0,0,-0.03505944,23.960915,22.372053)"
      style={{ fill: "#ffffff" }}
    >
      <g
        id="g4142"
        transform="matrix(-1,0,0,1,434.42519,0)"
        style={{ fill: "#ffffff" }}
      >
        <rect
          id="rect4116"
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            strokeWidth: 80,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(1,0,-0.7061362,0.7080761,0,0)"
          y="-0.70613718"
          x="181.50136"
          height="257.741"
          width="100"
        />
        <use
          xlinkHref="#rect4116"
          height="364"
          width="434"
          y="0"
          x="0"
          id="use4120"
          transform="matrix(1,0,-2.660624e-8,-1,-4.5291225e-8,363.5)"
          style={{ fill: "#ffffff" }}
        />
        <rect
          id="rect4122"
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            strokeWidth: 80,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          y="132"
          x="66.729935"
          height="100"
          width="367.69553"
        />
      </g>
    </g>
  </svg>
);

export default function ImageSlider({
  image1,
  image2,
  alt1 = "alt1",
  alt2 = "alt2",
  sliderColor = "red",
  sliderWidth = 3,
  sliderInitialPosition = 0.5,
  onSlide,
  onSlideEnd,
  handleBackgroundColor = "white",
  handleColor = "red",
  showHandle = true,
  customHandle = null,
  leftLabelText = null,
  rightLabelText = null,
  onLoadFirstImage,
  onLoadSecondImage,
  onErrorFirstImage,
  onErrorSecondImage,
  showPlaceholder = true,
  customPlaceholder = null,
  animate = false,
  animationCycleDuration = 5000,
}) {
  const [fromLeft, setFromLeft] = React.useState(null);
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const containerRef = React.useRef(null);
  const [containerSize, setContainerSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [firstImageLoaded, setFirstImageLoaded] = React.useState(false);
  const [secondImageLoaded, setSecondImageLoaded] = React.useState(false);
  const fromLeftRef = React.useRef(null);

  React.useEffect(() => {
    let step = 1;
    let widthClosure = 0;

    function animateSlider() {
      animationLoop = requestAnimationFrame(animateSlider);

      if (fromLeftRef.current !== null) {
        if (fromLeftRef.current >= widthClosure) {
          step *= -1;
        } else if (fromLeftRef.current <= 0) {
          step *= -1;
        }

        setFromLeft(fromLeftRef.current + step);

        fromLeftRef.current += step;
      }
    }

    if (containerRef && containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({
        width,
        height,
      });
      setFromLeft(width * sliderInitialPosition);

      if (animate) {
        fromLeftRef.current = width * sliderInitialPosition;
        step = Math.round((width / animationCycleDuration) * 16.6 * 100) / 100;
        widthClosure = width;

        animateSlider();
      }
    }
  }, [animate, animationCycleDuration, sliderInitialPosition]);

  React.useEffect(() => {
    if (!document) return;
    function handleMouseUp(e) {
      setIsMouseDown(false);
      onSlideEnd && onSlideEnd();
    }

    function handleMouseMove(e) {
      if (containerRef && containerRef.current && isMouseDown) {
        const { left, width } = containerRef.current.getBoundingClientRect();
        onSlide && onSlide();

        if (e.pageX - left < 0) {
          setFromLeft(0 - sliderWidth / 2);
        } else if (e.pageX > left + width) {
          setFromLeft(width - sliderWidth / 2);
        } else {
          setFromLeft(e.pageX - left);
        }
      }
    }

    function handleTouchMove(e) {
      if (containerRef && containerRef.current && isMouseDown) {
        const { left, width } = containerRef.current.getBoundingClientRect();

        if (e.touches[0].pageX - left < 0) {
          setFromLeft(0 - sliderWidth / 2);
        } else if (e.touches[0].pageX > left + width) {
          setFromLeft(width - sliderWidth / 2);
        } else {
          setFromLeft(e.touches[0].pageX - left);
        }
      }
    }

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isMouseDown, onSlide, onSlideEnd, sliderWidth]);

  const handleMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();

    cancelAnimationFrame(animationLoop);
    setIsMouseDown(true);
  };

  return (
    <div
      className="slider__wrapper"
      ref={containerRef}
      style={{
        cursor: isMouseDown ? "ew-resize" : "default",
      }}
    >
      {fromLeft !== null && (
        <>
          <div className="slider__container">
            <img
              alt={alt1}
              src={image1}
              width={containerSize.width}
              height={containerSize.height}
              onLoad={() => {
                onLoadFirstImage && onLoadFirstImage();
                setFirstImageLoaded(true);
              }}
              onError={() => {
                onErrorFirstImage && onErrorFirstImage();
              }}
            />

            {showPlaceholder &&
              !firstImageLoaded &&
              (customPlaceholder ? (
                <div
                  className="custom-placeholder__wrapper"
                  style={{ left: fromLeft }}
                >
                  {customPlaceholder}
                </div>
              ) : (
                <div className="placeholder" />
              ))}

            {rightLabelText && (
              <div className="label__text label__right">{rightLabelText}</div>
            )}
          </div>

          <div
            className="slider__container img-comp-overlay"
            style={{ width: fromLeft <= 0 ? 0 : fromLeft }}
          >
            <img
              alt={alt2}
              src={image2}
              width={containerSize.width}
              height={containerSize.height}
              onLoad={() => {
                onLoadSecondImage && onLoadSecondImage();
                setSecondImageLoaded(true);
              }}
              onError={() => {
                onErrorSecondImage && onErrorSecondImage();
              }}
            />

            {showPlaceholder &&
              !secondImageLoaded &&
              (customPlaceholder ? (
                <div className="custom-placeholder__wrapper">
                  {customPlaceholder}
                </div>
              ) : (
                <div className="placeholder" />
              ))}

            {leftLabelText && (
              <div className="label__text label__left">{leftLabelText}</div>
            )}
          </div>
          <div
            className="slider__stick"
            style={{
              left: fromLeft,
              backgroundColor: sliderColor,
              width: sliderWidth,
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
          >
            <div className="slider__handle">
              {showHandle && <SliderHandle />}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
